<template>
  <div>
    <!-- 智慧采购 -->
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
        <div class="indexlogo"></div>
      <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li  @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li  @click="go('/yhal')"><span class="active2">用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      </div>

      <div class="zhfwbutton"></div>
    </div>
    <!--  -->
    <div class="gyyqinduce">
      <div class="gyyqinducecontent">
        <div class="gyyqinducecontenttop">工业设计引擎将以“数字经济+工业设计”赋能浙江省乃至全国实体经济和制造业产业的高质量发展。利用“互联网+”的新型商业模式， 打造国内首个服务于制造业的数字智能化设计服务平台。实现政策精准推送、资源高效配置、需求动态匹配、技术辐射全省、创新驱动发展；解决各地市特色产业在产品设计、制造供应链、品牌推广等关键环节面临的信息、技术、人才、渠道等短缺问题；构建全省性工业设计产业化对接平台，建立现代服务业与先进制造业高质量融合发展样本。</div>
        <div class="gyyqinducecontentcenter"></div>
        <div class="gyyqinducecontentbottom"></div>
      </div>
    </div>
    <!--  -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../../components/TabarBottom.vue'
  export default {
    components:{TabarBottom},
    methods:{
      go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
//
.gyyqinduce{
  height: 1622px;
  .gyyqinducecontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    .gyyqinducecontenttop{
      padding-top: 59px;
      text-indent: 2rem;
      width: 1200px;
      height: 120px;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #282A2E;
      line-height: 34px;
    }
    .gyyqinducecontentcenter{
      width: 1200px;
      height: 726px;
      background: url('../../.././assets/gyyqinducecontentcenter.png') no-repeat center 0;
      margin-top: 31px;
    }
    .gyyqinducecontentbottom{
      background: url('../../.././assets/gyyqinducecontentbottom.png') no-repeat center 0;
      width: 1200px;
      height: 585px;
      margin-top: 31px;
      margin-bottom: 60px;
    }
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../../.././assets/yhal_gyyq.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../../.././assets/logo.png')  no-repeat center 0;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;

}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
